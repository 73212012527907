<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="员工订单详情" name="first">
        <div class="left">
          <div class="left_cont">
            <div>
              <el-table
                :header-cell-style="headeRowClass"
                :data="tableData"
                stripe
                style="width: 100%"
                :default-sort="{ prop: 'date', order: 'descending' }"
              >
                <el-table-column prop="realname" label="员工姓名" sortable>
                </el-table-column>
                <el-table-column prop="xiugaizhong.sh" label="修改中" sortable>
                </el-table-column>
                <el-table-column prop="shenhezhong.sh" label="审核中" sortable>
                </el-table-column>
                <el-table-column prop="yiwancheng.sh" label="已完成" sortable>
                </el-table-column>
                <el-table-column prop="quanbu.sh" label="全部" sortable>

                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="orderPage">

            <el-pagination
              class="fy"
              small
              @size-change="handleSizeChange"
              :page-size="pagesize"
              :page-sizes="[10, 20, 30]"
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="current_change"
              :total="total"
              background
            >
            </el-pagination>


          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { UserLists } from '../../../API/user'
export default {
  data () {
    return {
      form: {
        name: '',
        region: ''
      },
      //选项卡第一个默认选中
      activeName: 'first',
      //订单总数

      total: 0, //默认数据总数
      pagesize: 10, //每页的数据条数
      loading: false,
      currentPage: 1, //默认开始页面
      dialog: false, //默认不显示
      //表格数据
      tableData: []

    }
  },
  mounted () {
    this.UserList()
  },
  methods: {

    // 员工详情列表
    UserList () {
      let id = localStorage.getItem('FuWuId')
      UserLists(id, this.currentPage, this.pagesize).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
        console.log(this.tableData);
      })
    },
    //选项卡切换
    handleClick (tab, event) {
      console.log(tab, event);
    },
    //表格复选框选中事件
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    // 更改表头样式
    headeRowClass ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (rowIndex === 0) {
        return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
      }
    },
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.UserList()
    }, handleSizeChange (val) {
      console.log(val);
      this.pagesize = val;
      this.UserList()
    },
    //弹窗
    receive: function (row) {
      //显示弹框
      this.dialog = true;
    },
  }
};
</script>

<style>
.left,
.right {
  width: 98%;
  height: auto;
}

.left_title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.left_title span {
  margin-right: 10px;
}

.allocate {
  width: 100px;
  height: 40px;
  background: #ff1918;
  border-radius: 4px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  margin-left: 20px;
}

.orderPage {
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>